<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback /> {{ $t('TotalSumOfHandsCommission') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate="addPayload" />
      <v-row class="px-2">
        <v-col cols="12" md="6" class="py-0" lg="3">
          <v-select
            v-model="teamSelected"
            :items="teamList"
            :label="$t('staff_team')"
            item-text="teams_name"
            item-value="teams_id"
            outlined
            dense
            @change="addPayload(payload.start, payload.end)"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" class="py-0" lg="3">
          <v-select
            v-model.trim="typeSelected"
            :items="typeList"
            :label="$t('type')"
            dense
            outlined
            :item-text="$i18n.locale"
            item-value="value"
            @change="addPayload(payload.start, payload.end)"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.code`]="{ item }">
          <router-link
            v-if="item.opd_id"
            :to="{ name: 'ShowDetailCheck', params: { id: item.opd_id } }"
            class="font-weight-medium text-decoration-none"
            target="_blank"
          >
            {{ item.code }}
          </router-link>
          <router-link
            v-if="item.serve_id"
            :to="{ name: 'showDetailService', params: { id: item.serve_id } }"
            class="font-weight-medium text-decoration-none"
            target="_blank"
          >
            {{ item.code }}
          </router-link>
        </template>
        <template v-slot:[`item.order_id`]="{ item }">
          <router-link
            v-if="item.order_id"
            :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
            class="font-weight-medium text-decoration-none"
            target="_blank"
          >
            {{ item.order_id }}
          </router-link>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <span
            v-if="item.date == $t('total')"
            :class="item.date == $t('total') ? 'font-weight-bold primary--text' : ''"
          >
            {{ item.date }}
          </span>
          <span v-else>
            {{ item.date | sumdatetime }}
          </span>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <span :class="item.date == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.amount }}
          </span>
        </template>
      </v-data-table>
      <v-data-table
        :headers="columns2"
        :items="dataTableList2"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { i18n } from '@/plugins/i18n'
import { reportCommisTotal, removeComma } from '../useExcel'
import Comeback from '../Comeback.vue'
import { sumdate, sumdatetime } from '@/plugins/filters'
import teamReport from '@/api/teamReport'
import FeeCommissiomStatus from '@/@fake-db/data/FeeCommissiomStatus.json'

export default {
  components: {
    DateFilters,
    Comeback,
  },
  filters: {
    sumdatetime,
  },
  setup() {
    const teamList = ref([])
    const teamSelected = ref('')
    const typeList = ref(FeeCommissiomStatus.data)
    const typeSelected = ref('')
    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const dataTableList = ref([])
    const dataTableList2 = ref([])
    const dataExcel = ref([])
    const options = ref({})
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('a_number'), value: 'code', width: 130 },
      { text: i18n.t('receipt_code'), value: 'order_id', width: 130 },
      { text: i18n.t('staff_team'), value: 'teams_name', width: 220 },
      { text: i18n.t('list'), value: 'name', width: 280 },
      { text: i18n.t('type'), value: 'type', width: 100 },
      { text: i18n.t('date_of_receipt'), value: 'date', width: 170 },
      {
        text: i18n.t('net_amount'),
        value: 'amount',
        width: 180,
        align: 'end',
      },
    ])
    const columns2 = ref([
      { text: i18n.t('staff_team'), value: 'teams_name', width: 280 },
      {
        text: i18n.t('checkdf'),
        value: 'total_check',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('serviceCostDF'),
        value: 'total_service',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('check'),
        value: 'total_check_com',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('course'),
        value: 'total_course',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('drug'),
        value: 'total_drug',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('equipment'),
        value: 'total_equipment',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('memberCard'),
        value: 'total_member',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('net_amount'),
        value: 'total_all',
        width: 180,
        align: 'end',
      },
    ])

    const exportLoading = ref(false)
    teamReport.teamReportList().then(res => {
      teamList.value = res
      teamList.value.unshift({
        teams_id: '',
        teams_name: i18n.t('all'),
      })
    })

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        teams_id: teamSelected.value,
        type_id: typeSelected.value,
        lang: i18n.locale,
      }
      searchReport(payload.value)
    }
    const searchReport = async payload => {
      loading.value = true
      const { data, datateam } = await reportCommisTotal(payload)
      dataTableList.value = data
      dataTableList2.value = datateam
      dataExcel.value = mapDataTeam(datateam)
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }
    const mapDataTeam = datateam => {
      try {
        const data = datateam.map(item => ({
          number: item.teams_name,
          code: item.total_check,
          order_id: item.total_service,
          teams_name: item.total_course,
          name: item.total_drug,
          type: item.total_equipment,
          date: item.total_member,
          amount: item.total_all,
        }))

        return [
          {
            number: i18n.t('staff_team'),
            code: i18n.t('check'),
            order_id: i18n.t('service'),
            teams_name: i18n.t('course'),
            name: i18n.t('drug'),
            type: i18n.t('equipment'),
            date: i18n.t('memberCard'),
            amount: i18n.t('net_amount'),
          },
          ...data,
        ]
      } catch (err) {
        console.error(err)

        return []
      }
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      const fileName = `${i18n.t('TotalSumOfHandsCommission')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
        'to',
      )} ${sumdate(end)} .xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      //  รายงานวิเคราะห์รายการตรวจ //;
      let dataExport = dataTableList.value.map((item, i) => {
        delete item.opd_id
        delete item.opd_code
        delete item.order_id_pri
        delete item.serve_id
        delete item.serve_code

        return { number: i + 1, ...item }
      })
      dataExport = await removeComma(JSON.parse(JSON.stringify([...dataExport, {}, {}, ...dataExcel.value])))

      const Heading = [
        [
          `${i18n.t('TotalSumOfHandsCommission')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(
            end,
          )} `,
        ],
        [
          '#',
          `${i18n.t('a_number')}`,
          `${i18n.t('receipt_code')}`,
          `${i18n.t('staff_team')}`,
          `${i18n.t('list')}`,
          `${i18n.t('type')}`,
          `${i18n.t('date_of_receipt')}`,
          `${i18n.t('net_amount')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: ['number', 'code', 'order_id', 'teams_name', 'name', 'type', 'date', 'amount'],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }]
      const wscols = [
        { wch: 20 },
        { wch: 12 },
        { wch: 10 },
        { wch: 25 },
        { wch: 40 },
        { wch: 10 },
        { wch: 18 },
        { wch: 15 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('TotalSumOfHandsCommission')}`.replace('/', '-'))

      // พิมร์ files //;
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      teamList,
      teamSelected,
      typeList,
      typeSelected,
      XLSX,
      payload,
      loading,
      dataTableList,
      dataTableList2,
      dataExcel,
      options,
      columns,
      columns2,
      exportLoading,
      exportExcel,
      addPayload,
      mdiFileExcelOutline,
    }
  },
}
</script>
